define("discourse/plugins/discourse-chat-integration/admin/templates/plugins-chat-integration-provider", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.anyErrors}}
    <div class="error">
      {{d-icon "exclamation-triangle"}}
      <span class="error-message">
        {{i18n "chat_integration.channels_with_errors"}}
      </span>
    </div>
  {{/if}}
  
  {{#each this.model.channels.content as |channel|}}
    <ChannelDetails
      @channel={{channel}}
      @provider={{this.model.provider}}
      @refresh={{action "refresh"}}
      @editChannel={{action "editChannel"}}
      @test={{action "testChannel"}}
      @createRule={{action "createRule"}}
      @editRuleWithChannel={{action "editRuleWithChannel"}}
      @showError={{action "showError"}}
    />
  {{/each}}
  
  <div class="table-footer">
    <div class="pull-right">
      <DButton
        @id="create-channel"
        @icon="plus"
        @title="chat_integration.create_channel"
        @label="chat_integration.create_channel"
        @action={{action "createChannel" this.model.provider}}
      />
    </div>
  </div>
  */
  {
    "id": "suVrC5zB",
    "block": "[[[41,[30,0,[\"anyErrors\"]],[[[1,\"  \"],[10,0],[14,0,\"error\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"exclamation-triangle\"],null]],[1,\"\\n    \"],[10,1],[14,0,\"error-message\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[\"chat_integration.channels_with_errors\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"model\",\"channels\",\"content\"]]],null]],null],null,[[[1,\"  \"],[8,[39,7],null,[[\"@channel\",\"@provider\",\"@refresh\",\"@editChannel\",\"@test\",\"@createRule\",\"@editRuleWithChannel\",\"@showError\"],[[30,1],[30,0,[\"model\",\"provider\"]],[28,[37,8],[[30,0],\"refresh\"],null],[28,[37,8],[[30,0],\"editChannel\"],null],[28,[37,8],[[30,0],\"testChannel\"],null],[28,[37,8],[[30,0],\"createRule\"],null],[28,[37,8],[[30,0],\"editRuleWithChannel\"],null],[28,[37,8],[[30,0],\"showError\"],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[10,0],[14,0,\"table-footer\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"pull-right\"],[12],[1,\"\\n    \"],[8,[39,9],null,[[\"@id\",\"@icon\",\"@title\",\"@label\",\"@action\"],[\"create-channel\",\"plus\",\"chat_integration.create_channel\",\"chat_integration.create_channel\",[28,[37,8],[[30,0],\"createChannel\",[30,0,[\"model\",\"provider\"]]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"channel\"],false,[\"if\",\"div\",\"d-icon\",\"span\",\"i18n\",\"each\",\"-track-array\",\"channel-details\",\"action\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/templates/plugins-chat-integration-provider.hbs",
    "isStrictMode": false
  });
});