define("discourse/plugins/discourse-chat-integration/admin/controllers/admin-plugins-chat-integration-provider", ["exports", "@ember/controller", "discourse/lib/show-modal", "@glimmer/tracking", "@ember/object"], function (_exports, _controller, _showModal, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MODALS = {
    editChannel: "admin-plugins-chat-integration-edit-channel",
    testChannel: "admin-plugins-chat-integration-test",
    editRule: "admin-plugins-chat-integration-edit-rule",
    channelError: "admin-plugins-chat-integration-channel-error"
  };
  class AdminPluginsChatIntegrationEditRule extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "modalShowing", [_tracking.tracked], function () {
      return false;
    }))();
    #modalShowing = (() => (dt7948.i(this, "modalShowing"), void 0))();
    get anyErrors() {
      const channels = this.model.channels;
      let anyErrors = false;
      channels.forEach(channel => {
        if (channel.error_key) {
          anyErrors = true;
        }
      });
      return anyErrors;
    }
    triggerModal(model, modal) {
      this.modalShowing = true;
      (0, _showModal.default)(modal, {
        model,
        admin: true
      });
    }
    createChannel() {
      return this.triggerModal({
        channel: this.store.createRecord("channel", {
          provider: this.model.provider.id,
          data: {}
        }),
        provider: this.model.provider
      }, MODALS.editChannel);
    }
    static #_2 = (() => dt7948.n(this.prototype, "createChannel", [_object.action]))();
    editChannel(channel) {
      return this.triggerModal({
        channel,
        provider: this.model.provider
      }, MODALS.editChannel);
    }
    static #_3 = (() => dt7948.n(this.prototype, "editChannel", [_object.action]))();
    testChannel(channel) {
      return this.triggerModal({
        channel
      }, MODALS.testChannel);
    }
    static #_4 = (() => dt7948.n(this.prototype, "testChannel", [_object.action]))();
    createRule(channel) {
      return this.triggerModal({
        rule: this.store.createRecord("rule", {
          channel_id: channel.id,
          channel
        }),
        channel,
        provider: this.model.provider,
        groups: this.model.groups
      }, MODALS.editRule);
    }
    static #_5 = (() => dt7948.n(this.prototype, "createRule", [_object.action]))();
    editRuleWithChannel(rule, channel) {
      return this.triggerModal({
        rule,
        channel,
        provider: this.model.provider,
        groups: this.model.groups
      }, MODALS.editRule);
    }
    static #_6 = (() => dt7948.n(this.prototype, "editRuleWithChannel", [_object.action]))();
    showError(channel) {
      return this.triggerModal({
        channel
      }, MODALS.channelError);
    }
    static #_7 = (() => dt7948.n(this.prototype, "showError", [_object.action]))();
    refresh() {
      this.send("refreshProvider");
    }
    static #_8 = (() => dt7948.n(this.prototype, "refresh", [_object.action]))();
  }
  _exports.default = AdminPluginsChatIntegrationEditRule;
});