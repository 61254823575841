define("discourse/plugins/discourse-chat-integration/admin/controllers/modals/admin-plugins-chat-integration-edit-channel", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse/lib/ajax-error", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _modalFunctionality, _ajaxError, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsChatIntegrationEditChannel extends _controller.default.extend(_modalFunctionality.default) {
    static #_ = (() => dt7948.g(this.prototype, "validParams", [_tracking.tracked], function () {
      return false;
    }))();
    #validParams = (() => (dt7948.i(this, "validParams"), void 0))();
    isValidParams(validity) {
      return this.validParams = validity;
    }
    static #_2 = (() => dt7948.n(this.prototype, "isValidParams", [_object.action]))();
    handleKeyUp(e) {
      if (e.code === "Enter" && this.validParams) {
        this.save();
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "handleKeyUp", [_object.action]))();
    cancel() {
      this.send("closeModal");
    }
    static #_4 = (() => dt7948.n(this.prototype, "cancel", [_object.action]))();
    save() {
      if (!this.validParams) {
        return;
      }
      this.model.channel.save().then(() => {
        this.send("closeModal");
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_5 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
  }
  _exports.default = AdminPluginsChatIntegrationEditChannel;
});