define("discourse/plugins/discourse-chat-integration/admin/controllers/modals/admin-plugins-chat-integration-test", ["exports", "@ember/controller", "@ember/object/computed", "I18n", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _computed, _I18n, _modalFunctionality, _ajax, _ajaxError, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsChatIntegrationTest extends _controller.default.extend(_modalFunctionality.default) {
    static #_ = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "sendDisabled", [(0, _computed.not)("model.topic_id")]))();
    #sendDisabled = (() => (dt7948.i(this, "sendDisabled"), void 0))();
    handleKeyUp(e) {
      if (e.code === "Enter" && !this.sendDisabled) {
        this.send();
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "handleKeyUp", [_object.action]))();
    send() {
      if (this.sendDisabled) {
        return;
      }
      this.loading = true;
      (0, _ajax.ajax)("/admin/plugins/chat-integration/test", {
        data: {
          channel_id: this.model.channel.id,
          topic_id: this.model.topic_id
        },
        type: "POST"
      }).then(() => {
        this.loading = false;
        this.flash(_I18n.default.t("chat_integration.test_modal.success"), "success");
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_4 = (() => dt7948.n(this.prototype, "send", [_object.action]))();
  }
  _exports.default = AdminPluginsChatIntegrationTest;
});