define("discourse/plugins/discourse-chat-integration/discourse/templates/connectors/user-custom-preferences/webhook-preferences", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">Webhook Message Content</label>
    <div class="controls">
        <label class="text-label">
          {{input type="text" class="input-xxlarge" value=model.custom_fields.chat_integration_discord_message_content}}
        </label>
    </div>
  </div>
  */
  {
    "id": "rQ1nqctD",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"Webhook Message Content\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"text-label\"],[12],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"type\",\"class\",\"value\"],[\"text\",\"input-xxlarge\",[30,0,[\"model\",\"custom_fields\",\"chat_integration_discord_message_content\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-chat-integration/discourse/templates/connectors/user-custom-preferences/webhook-preferences.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"div\",\"label\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/discourse/templates/connectors/user-custom-preferences/webhook-preferences.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});