define("discourse/plugins/discourse-chat-integration/admin/components/channel-param-row", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr class="input">
    <td class="label">
      <label for="param-{{@param.key}}">
        {{i18n
          (concat
            "chat_integration.provider."
            @model.channel.provider
            ".param."
            @param.key
            ".title"
          )
        }}
      </label>
    </td>
    <td>
      <Input
        name={{concat "param-" @param.key}}
        @value={{this.inputValue}}
        {{on "change" this.updateValue}}
      />
  
      <InputTip @validation={{this.validate}} />
  
    </td>
  </tr>
  
  <tr class="chat-instructions">
    <td></td>
    <td>
      <label>
        {{i18n
          (concat
            "chat_integration.provider."
            @model.channel.provider
            ".param."
            @param.key
            ".help"
          )
        }}
      </label>
    </td>
  </tr>
  */
  {
    "id": "hf2FKjtZ",
    "block": "[[[10,\"tr\"],[14,0,\"input\"],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"label\"],[12],[1,\"\\n    \"],[10,\"label\"],[15,\"for\",[29,[\"param-\",[30,1,[\"key\"]]]]],[12],[1,\"\\n      \"],[1,[28,[35,3],[[28,[37,4],[\"chat_integration.provider.\",[30,2,[\"channel\",\"provider\"]],\".param.\",[30,1,[\"key\"]],\".title\"],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,5],[[16,3,[28,[37,4],[\"param-\",[30,1,[\"key\"]]],null]],[4,[38,6],[\"change\",[30,0,[\"updateValue\"]]],null]],[[\"@value\"],[[30,0,[\"inputValue\"]]]],null],[1,\"\\n\\n    \"],[8,[39,7],null,[[\"@validation\"],[[30,0,[\"validate\"]]]],null],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"tr\"],[14,0,\"chat-instructions\"],[12],[1,\"\\n  \"],[10,\"td\"],[12],[13],[1,\"\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[[28,[37,4],[\"chat_integration.provider.\",[30,2,[\"channel\",\"provider\"]],\".param.\",[30,1,[\"key\"]],\".help\"],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@param\",\"@model\"],false,[\"tr\",\"td\",\"label\",\"i18n\",\"concat\",\"input\",\"on\",\"input-tip\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/components/channel-param-row.hbs",
    "isStrictMode": false
  });
  class ChannelParamRow extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "inputValue", [_tracking.tracked], function () {
      return this.args.model.channel.data[this.args.param.key] || "";
    }))();
    #inputValue = (() => (dt7948.i(this, "inputValue"), void 0))();
    get validate() {
      const parameter = this.args.param;
      const regString = parameter.regex;
      const regex = new RegExp(regString);
      if (this.inputValue === "") {
        // Fail silently if field blank
        this.args.isValidParams(false);
        return _object.default.create({
          failed: true
        });
      } else if (!regString) {
        // Pass silently if no regex available for provider
        this.args.isValidParams(true);
        return _object.default.create({
          ok: true
        });
      } else if (regex.test(this.inputValue)) {
        // Test against regex
        this.args.isValidParams(true);
        return _object.default.create({
          ok: true,
          reason: _I18n.default.t("chat_integration.edit_channel_modal.channel_validation.ok")
        });
      } else {
        // Failed regex
        this.args.isValidParams(false);
        return _object.default.create({
          failed: true,
          reason: _I18n.default.t("chat_integration.edit_channel_modal.channel_validation.fail")
        });
      }
    }
    updateValue(event) {
      this.args.model.channel.data[this.args.param.key] = event.target.value;
    }
    static #_2 = (() => dt7948.n(this.prototype, "updateValue", [_object.action]))();
  }
  _exports.default = ChannelParamRow;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChannelParamRow);
});