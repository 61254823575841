define("discourse/plugins/discourse-chat-integration/admin/routes/admin-plugins-chat-integration-provider", ["exports", "discourse/routes/discourse", "discourse/models/group", "@ember/object", "rsvp"], function (_exports, _discourse, _group, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsChatIntegrationProvider extends _discourse.default {
    model(params) {
      return _rsvp.default.hash({
        channels: this.store.findAll("channel", {
          provider: params.provider
        }),
        provider: this.modelFor("admin-plugins-chat-integration").findBy("id", params.provider),
        groups: _group.default.findAll()
      }).then(value => {
        value.channels.forEach(channel => {
          channel.set("rules", channel.rules.map(rule => {
            rule = this.store.createRecord("rule", rule);
            rule.set("channel", channel);
            return rule;
          }));
        });
        return value;
      });
    }
    serialize(model) {
      return {
        provider: model["provider"].get("id")
      };
    }
    closeModal() {
      if (this.controller.modalShowing) {
        this.refresh();
        this.controller.modalShowing = false;
      }
      return true; // Continue bubbling up, so the modal actually closes
    }
    static #_ = (() => dt7948.n(this.prototype, "closeModal", [_object.action]))();
    refreshProvider() {
      this.refresh();
    }
    static #_2 = (() => dt7948.n(this.prototype, "refreshProvider", [_object.action]))();
  }
  _exports.default = AdminPluginsChatIntegrationProvider;
});