define("discourse/plugins/discourse-chat-integration/admin/routes/admin-plugins-chat-integration", ["exports", "discourse/routes/discourse", "@ember/object"], function (_exports, _discourse, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsChatIntegration extends _discourse.default {
    model() {
      return this.store.findAll("provider");
    }
    showSettings() {
      this.transitionTo("adminSiteSettingsCategory", "plugins", {
        queryParams: {
          filter: "chat_integration"
        }
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "showSettings", [_object.action]))();
  }
  _exports.default = AdminPluginsChatIntegration;
});