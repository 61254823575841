define("discourse/plugins/discourse-chat-integration/admin/controllers/modals/admin-plugins-chat-integration-edit-rule", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse/lib/ajax-error", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _controller, _modalFunctionality, _ajaxError, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsChatIntegrationEditRule extends _controller.default.extend(_modalFunctionality.default) {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "saveDisabled", [_tracking.tracked], function () {
      return false;
    }))();
    #saveDisabled = (() => (dt7948.i(this, "saveDisabled"), void 0))();
    get showCategory() {
      return this.model.rule.type === "normal";
    }
    get currentRuleType() {
      return this.model.rule.type;
    }
    save(rule) {
      if (this.saveDisabled) {
        return;
      }
      rule.save().then(() => this.send("closeModal")).catch(_ajaxError.popupAjaxError);
    }
    static #_3 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    handleKeyUp(e) {
      if (e.code === "Enter") {
        this.save();
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "handleKeyUp", [_object.action]))();
    onChangeRuleType(type) {
      this.model.rule.type = type;
      this.currentRuleType = type;
      if (type !== "normal") {
        this.showCategory = false;
      } else {
        this.showCategory = true;
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "onChangeRuleType", [_object.action]))();
  }
  _exports.default = AdminPluginsChatIntegrationEditRule;
});